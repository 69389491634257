import React, { useState, useEffect, useContext } from 'react'
import { TableBody, Table, TableContainer, CircularProgress, TableHead, TableRow, Paper, TableCell } from '@material-ui/core'
import { formattedTime } from 'utils'
import I from 'immutable'
import Context from 'reactContext'
import Agent from 'components/agent'
import { get } from 'utils/request'

const Recharges = () => {
  const [loading, setLoading] = useState(true)
  const { users, usersSet } = useContext(Context)
  const [payments, setPayments] = useState([])
  const rowData = Object.values(payments)
    .reduce((v, k) => v.concat(k), [])
    .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))

  const rechargesIndex = async () => {
    const res = await get('agents/managers/recharges')
    if (res.ok) {
      setPayments(res.recharges)
      setLoading(false)
    }
  }

  const usersIndex = async () => {
    const res = await get('agents/managers')
    if (res.ok) {
      usersSet(I.fromJS(res.data))
    }
  }

  useEffect(() => {
    rechargesIndex()
    usersIndex()
  }, [])

  return (
    <Agent>
      <div className="text-center">
        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>充值时间</TableCell>
                  <TableCell>企业名称</TableCell>
                  <TableCell>充值金额</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((v) => (
                  <TableRow key={v.created_at}>
                    <TableCell align="left">{formattedTime(v.created_at)}</TableCell>
                    <TableCell align="left">
                      {users
                        .filter((u) => u.get('id') === v.manager_id)
                        .map((u) => u.get('name'))
                        .toArray().length === 0
                        ? ''
                        : users
                            .filter((u) => u.get('id') === v.manager_id)
                            .map((u) => u.get('name'))
                            .toArray()[0][1]}
                    </TableCell>
                    <TableCell align="left">{`¥ ${v.amount / 100}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Agent>
  )
}

export default Recharges
